<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-profile></sidebar-profile>
          <div class="page__content">
            <Card title="Endereço" id="endereco">
              <div class="row">
                <Input
                  v-model="form.address.cep"
                  label="CEP"
                  mask="#####-###"
                  class="col-md-4"
                />
                <Input
                  v-model="form.address.street"
                  label="Rua"
                  class="col-md-8"
                />
                <Input
                  v-model="form.address.number"
                  label="Número"
                  class="col-md-4"
                />
                <Input
                  v-model="form.address.complement"
                  label="Complemento"
                  class="col-md-8"
                />
                <Input
                  v-model="form.address.district"
                  label="Bairro"
                  class="col-md-4"
                />
                <Select
                  label="Estado"
                  v-model="form.address.province_id"
                  :items.sync="provinces"
                  labelName="name"
                  class="col-12 col-md-4"
                />
                <Select
                  label="Cidade"
                  v-model="form.address.city_id"
                  :items.sync="cities"
                  class="col-12 col-md-4"
                  labelName="name"
                />
                <div class="col-12">
                  <button
                    @click="saveAddress"
                    type="button"
                    class="button_outline"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </Card>
            <Card title="Dados pessoais" id="dados-pessoais">
              <div class="row">
                <Input
                  v-model="form.userdata.name"
                  label="Nome completo"
                />
                <Input
                  v-if="form.userdata.type === 'PF'"
                  v-model="form.userdata.cpf"
                  :readonly="true"
                  label="CPF"
                  mask="###.###.###-##"
                />
                <Input
                  v-if="form.userdata.type === 'PJ'"
                  v-model="form.userdata.cnpj"
                  :readonly="true"
                  label="CNPJ"
                  mask="##.###.###.####-##"
                />
                <div class="col-12">
                  <button
                    @click="saveData"
                    type="button"
                    class="button_outline"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </Card>
            <Card title="Dados de acesso" id="dados-de-acesso">
              <div class="row">
                <Input
                  type="email"
                  :readonly="true"
                  v-model="form.userdata.email"
                  label="E-mail de cadastro"
                />
                <Input
                  type="password"
                  v-model="form.userdata.password"
                  label="Senha"
                  placeholder="**********"
                />
                <div class="col-12">
                  <button
                    @click="saveData"
                    type="button"
                    class="button_outline"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import swal from 'sweetalert'

const defaultForm = {
  address: {
    zip_code: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    province_id: '',
    city_id: ''
  },
  userdata: {
    company_name: '',
    id: '',
    cpf: ''
  },
  complemento: '',
  email: '',
  password: ''
}

export default {
  data: () => ({
    form: { ...defaultForm }
  }),
  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('city', { fetchCities: 'fetch', getViaCEP: 'getViaCEP' }),
    async saveAddress () {
      this.loading = true
      try {
        const data = this.form.address
        const url = 'auth/address/update'
        await axios.post(url, data)

        swal({
          title: 'Sucesso!',
          text: 'Endereço atualizado com sucesso.',
          icon: 'success',
          timer: 4000
        }).then(() => {
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    async saveData () {
      this.loading = true
      try {
        const data = this.form.userdata
        const url = 'auth/user/update'
        await axios.post(url, data)

        swal({
          title: 'Sucesso!',
          text: 'Dados pessoais atualizados com sucesso.',
          icon: 'success',
          timer: 4000
        }).then(() => {
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    async getData () {
      const url = `auth/user/user?id=${this.id}`
      const { data } = await this.$http.post(url)
      this.form.address = data.data.address[0]
      this.form.complemento = data.data.address[0].complement
      this.form.userdata = data.data.dados[0]
      this.loading = false
      // TODO: chamar api para buscar os dados do user e salvar na variavel this.form
    }
  },
  computed: {
    ...mapGetters('province', ['provinces']),
    ...mapGetters('city', ['cities'])
  },
  mounted () {
    const localUser = localStorage.getItem('user')
    const user = JSON.parse(localUser)
    this.id = user.id
    this.fetchProvinces()
    this.fetchCities()
    this.getData()
  },
  components: {
    sidebarProfile: () => import('@/components/sidebar-profile'),
    Card: () => import('@/components/card'),
    Input: () => import('@/components/input'),
    Select: () => import('@/components/select')
  },
  watch: {
    'form.address.province_id' (val, old) {
      this.fetch({ province: val })
      if (old) this.form.city_id = undefined
    },
    async 'form.address.cep' (val) {
      if (this.isEditing) return
      if (!val || val.length < 8) return
      const data = await this.getViaCEP({ zipCode: val })
      this.form.address = Object.assign(this.form.address, data)
      this.form.address.complement = this.form.complemento
      Object.keys(data).map(item => {
        this.loadedByZip[item] = !!data[item]
      })
    }
  }
}
</script>

<style scoped>
button {
  margin-left: 0.5rem;
  padding: 1rem;
  font-weight: bold;
  min-width: 25%;
}
</style>
